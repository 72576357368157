import React, { useEffect, useRef, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import _ from "lodash";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { IconButton, makeStyles } from "@material-ui/core";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { FilterList, Refresh, Search } from "@material-ui/icons";
import { RENDER } from "../../components/orders-core/MUITable";
import { getUserId, getUserWarehouse } from "../../reducers/ProfileReducer";
import { clearHybridOrders } from "../../reducers/HybridOrdersReducer";
import {
  getIpsBatchSyncList,
  ipsOrderSync,
} from "../../api/admin/AdminBatchHistoryUpdateApi";
import { formatDateTimeToUrlWithTSeconds } from "../../helpers/FormatUtils";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import IpsBatchSyncMuiTable, {
  ACTION,
} from "../../components/orders-core/IpsBatchSyncMuiTable";
import IpsBatchSyncProgressDialog from "../../components/orders-core/IpsBatchSyncProgressDialog";
import ChipTextField from "../../components/deprecated/ChipTextField";
import IpsBatchSyncFilterDialog from "../../wrappers/admin/IpsBatchSyncFilterDialog";
import IpsBatchSyncPushByRangeDialog from "../../components/orders-core/IpsBatchSyncPushByRangeDialog";

const enhancer = compose(
  connect(
    state => ({
      userWarehouse: getUserWarehouse(state),
      userId: getUserId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage, clearHybridOrders },
  ),
);
const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginTop: "10px",
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  input: { "& input": { fontSize: "20px" } },
});

const AdminIpsBatchSyncList = props => {
  const { getLocalisationMessage, showErrorMessage: showErrorMessage1 } = props;
  const classes = useStyles();
  const [openAll, setOpenAll] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isOpenByRange, setIsOpenByRange] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenSuccessLoading, setIsOpenSuccessLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [id, setId] = useState(null);

  const [filter, setFilter] = useState({
    refresh: false,
    size: 20,
    is_out_bound: false,
    barcodes: [],
  });

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  useEffect(() => {
    if (
      (filter.from_date && filter.to_date) ||
      (filter.barcodes && filter.barcodes.length > 0)
    ) {
      setIsLoading(true);
      getIpsBatchSyncList(filter)
        .then(res => {
          setIsLoading(false);
          setList(_.get(res, "data"));
        })
        .catch(error => {
          setIsLoading(false);
          showErrorMessage1(error);
        });
    }
  }, [filter]);

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });
  return (
    <AdminAppLayout
      title={getLocalisationMessage("ips_batch_sync", "IPS Batch Sync")}
      className={classes.contentRoot}
    >
      <IpsBatchSyncFilterDialog
        open={openFilter}
        onRequestClose={() => setOpenFilter(false)}
        filter={filter}
        onDismiss={() => {
          setOpenFilter(false);
          setFilter({
            refresh: false,
            size: 20,
            is_out_bound: false,
            barcodes: [],
          });
        }}
        onSubmit={v => {
          setFilter({
            ...filter,
            barcodes: [],
            from_date: formatDateTimeToUrlWithTSeconds(v.fromDate),
            to_date: formatDateTimeToUrlWithTSeconds(v.toDate),
          });

          setOpenFilter(false);
        }}
      />

      <IpsBatchSyncProgressDialog
        open={isOpenSuccess}
        onClose={() => {
          setIsOpenSuccess(false);
          setIsOpenByRange(false);
          setOpenAll(false);
          setId(null);
          setFilter(prev => ({
            ...prev,
            barcodes: [],
          }));
          setSelectedItems([]);
          refreshOrderList();
          setList([]);
        }}
        isOpenSuccessLoading={isOpenSuccessLoading}
      />

      {isOpenByRange && (
        <IpsBatchSyncPushByRangeDialog
          open={isOpenByRange}
          setIsOpenSuccessLoading={setIsOpenSuccessLoading}
          setIsOpenSuccess={setIsOpenSuccess}
          onRequestClose={() => {
            setIsOpenByRange(false);
          }}
        />
      )}

      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        onConfirm={() => {
          setIsOpenSuccessLoading(true);
          setIsOpenSuccess(true);
          ipsOrderSync({
            ...filter,
            barcodes: selectedItems,
          })
            .then(() => {
              setSelectedItems([]);
              refreshOrderList();
              setTimeout(() => {
                setFilter(prev => ({
                  ...prev,
                  barcodes: [],
                }));
              }, 300);
              setIsOpenSuccessLoading(false);
            })
            .catch(error => {
              props.showErrorMessage(error);
              setIsOpenSuccessLoading(false);
            });
        }}
        open={openAll}
        onRequestClose={() => setOpenAll(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_sync_the_selected_orders_with_ips",
        )}
      </ConfirmDialog>

      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        onConfirm={() => {
          setIsOpenSuccessLoading(true);
          setIsOpenSuccess(true);
          ipsOrderSync({
            ...filter,
            barcodes: [id],
          })
            .then(() => {
              setId(null);
              setSelectedItems([]);
              setFilter(prev => ({
                ...prev,
                barcodes: [],
              }));
              setList([]);
              refreshOrderList();
              setIsOpenSuccessLoading(false);
            })
            .catch(error => {
              props.showErrorMessage(error);
              setIsOpenSuccessLoading(false);
            });
        }}
        open={id}
        onRequestClose={() => setId(null)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_sync_the_selected_orders_with_ips",
        )}
      </ConfirmDialog>

      <IpsBatchSyncMuiTable
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filter={filter}
        isLoading={isLoading}
        list={list}
        withCheckbox={true}
        rowsPerPage={filter.size}
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            style={{ padding: ".25rem 1rem", gap: 13 }}
            justify="space-between"
          >
            {selectedItems.length > 0 ? (
              <FlexBox flex={true}>
                {selectedItems.length}{" "}
                {getLocalisationMessage("items_selected", "item(s) selected")}.
              </FlexBox>
            ) : (
              <React.Fragment>
                {!(filter.from_date && filter.to_date) ? (
                  <div className={classes.search} ref={ref}>
                    <Search
                      fontSize="large"
                      style={{
                        color: "rgba(0, 0, 0, 0.23)",
                        top: ".5rem",
                        left: ".5rem",
                        position: "absolute",
                      }}
                    />
                    <ChipTextField
                      style={{
                        padding: ".5rem 1rem 0 3rem",
                        maxHeight: divHeight > 86 && "5.8rem",
                        overflow: divHeight > 86 && "auto",
                      }}
                      fullWidth={true}
                      addOnBlur={false}
                      clearOnBlur={false}
                      value={filter.barcodes}
                      disableUnderline={true}
                      placeholder={getLocalisationMessage(
                        "type_here_to_search",
                      )}
                      onChange={v => setFilter({ ...filter, barcodes: v })}
                    />
                  </div>
                ) : (
                  <FlexBox flex={true} />
                )}
              </React.Fragment>
            )}
            <FlexBox
              style={{ minWidth: 300 }}
              justify="flex-end"
              align={ALIGN_CENTER}
            >
              <CustomButton
                onClick={() => setIsOpenByRange(true)}
                variant={CONTAINED}
                color={SECONDARY}
                style={{
                  borderRadius: "20px",
                  marginRight: 10,
                  backgroundColor: "#3F51B5",
                }}
              >
                {getLocalisationMessage("sync_by_range", "Sync by range")}
              </CustomButton>

              {selectedItems && selectedItems.length > 0 && (
                <CustomButton
                  onClick={() => setOpenAll(true)}
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{
                    borderRadius: "20px",
                    marginRight: 10,
                    backgroundColor: "#3F51B5",
                  }}
                >
                  {getLocalisationMessage("sync_selected", "Sync selected")}
                </CustomButton>
              )}

              <IconButton
                onClick={() => setOpenFilter(true)}
                style={{
                  marginRight: 10,
                }}
              >
                <FilterList />
              </IconButton>

              <IconButton
                className={isLoading && classes.refresh}
                tooltip={getLocalisationMessage("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
            </FlexBox>
          </FlexBox>
        }
        withoutPagination={true}
        columns={[
          {
            type: RENDER,
            label: getLocalisationMessage("barcode", "Barcode"),
            name: "id",
            align: "center",
          },
          {
            type: ACTION,
            name: (
              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px", marginRight: 10 }}
              >
                {getLocalisationMessage("sync", "Sync")}
              </CustomButton>
            ),
            label: getLocalisationMessage("action", "Action"),
            dispatch: rowId => setId(rowId),
            align: "center",
          },
        ]}
      />
    </AdminAppLayout>
  );
};
AdminIpsBatchSyncList.propTypes = {
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default enhancer(AdminIpsBatchSyncList);
